import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import Link from "../../Atoms/Link";
import { colors } from "../../../../styles/theme";
import IconSvg from "../../Atoms/Svg";

function SocialLinks({ facebookLink, twitterLink }) {
  const { t } = useTranslation();
  return (
    <>
      {facebookLink && facebookLink.url && (
        <SocialLink link={facebookLink}>
          <IconSvg name="facebook" fill={colors.facebook} />
          <span>{t("facebook_label")}</span>
        </SocialLink>
      )}
      {twitterLink && twitterLink.url && (
        <SocialLink link={twitterLink}>
          <IconSvg name="twitter" fill={colors.twitter} />
          <span>{t("twitter_label")}</span>
        </SocialLink>
      )}
    </>
  );
}

SocialLinks.propTypes = {
  facebookLink: PropTypes.object,
  twitterLink: PropTypes.object,
};

export default SocialLinks;

const SocialLink = styled(Link)`
  display: inline-flex;
  margin-top: 1rem;
  align-items: center;

  & + & {
    margin-left: 1rem;
  }

  > * + * {
    margin-left: 0.5rem;
  }
`;
