import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { A11y, Navigation, Scrollbar } from "swiper";

import Image from "../../Atoms/Image";
import Button from "../../Atoms/Button";
import IconSvg from "../../Atoms/Svg";
import { OswaldOverline1, OswaldOverline3 } from "../../Atoms/Typography";
import { colors, mediaquery, scaleSpacing } from "../../../../styles/theme";

SwiperCore.use([Navigation, Scrollbar, A11y]);

function Gallery({
  breakpoints,
  className,
  freeMode,
  uid: id,
  images,
  spaceBetween,
  title,
  withScrollbar,
}) {
  return (
    <Container className={className}>
      <Header>
        <Title>{title}</Title>
        <NavigationButtons id={id} />
      </Header>
      {images && (
        <Swiper
          mousewheel
          grabCursor
          spaceBetween={spaceBetween}
          navigation={{
            prevEl: `.prev-${id}`,
            nextEl: `.next-${id}`,
          }}
          breakpoints={breakpoints}
          freeMode={freeMode}
          scrollbar={{
            el: `.swiper-scrollbar-${id}`,
            draggable: true,
          }}
        >
          {images.map(({ image }, i) => (
            <SwiperSlide key={i}>
              <StyledImage source={image} sizes="100vw" mobileSizes="100vw" />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <CarouselScrollbar
        hidden={!withScrollbar}
        className={`swiper-scrollbar-${id}`}
      />
    </Container>
  );
}

Gallery.propTypes = {
  breakpoints: PropTypes.object,
  className: PropTypes.string,
  freeMode: PropTypes.bool,
  uid: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  spaceBetween: PropTypes.number,
  title: PropTypes.string.isRequired,
  withScrollbar: PropTypes.bool,
};

Gallery.defaultProps = {
  freeMode: false,
  spaceBetween: 16,
  withScrollbar: false,
};

export default Gallery;

function NavigationButtons({ id }) {
  return (
    <Controls className="gallery-controls">
      <GalleryButton
        className={`prev prev-${id}`}
        buttonTheme="iconAlpha"
        ariaLabel="Previous"
      >
        <IconSvg name="arrow" size={12} fill={colors.white} />
      </GalleryButton>
      <GalleryButton
        className={`next next-${id}`}
        buttonTheme="iconAlpha"
        ariaLabel="Next"
      >
        <IconSvg name="arrow" size={12} fill={colors.white} />
      </GalleryButton>
    </Controls>
  );
}

NavigationButtons.propTypes = {
  id: PropTypes.string.isRequired,
};

const Container = styled.div`
  overflow: hidden;

  .swiper-container {
    overflow: initial;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
`;

const Controls = styled.div`
  display: none;
  ${mediaquery.sm(css`
    display: flex;
  `)}

  margin-left: auto;
  > button + button {
    margin-left: 1rem;
  }
`;

const GalleryButton = styled(Button)`
  background-color: ${colors.mediumgrey1};

  ${mediaquery.sm(css`
    width: ${scaleSpacing(8)};
    height: ${scaleSpacing(10)};
  `)}

  &.prev svg {
    transform: rotate(180deg);
  }

  &.swiper-button-disabled {
    opacity: 0.4;
  }
`;

const Title = styled.h2`
  ${OswaldOverline3}

  ${mediaquery.md(css`
    ${OswaldOverline1}
  `)}
`;

const StyledImage = styled(Image)`
  img {
    width: auto;
  }
`;

const CarouselScrollbar = styled.div`
  position: relative;
  margin-top: ${scaleSpacing(8)};
  height: ${scaleSpacing(1)};
  background-color: ${colors.mediumgrey2};
  border-radius: ${scaleSpacing(15)};
  cursor: grab;
  display: none ${({ hidden }) => hidden && "!important"};

  ${mediaquery.sm(css`
    display: block;
  `)};

  .swiper-scrollbar-drag {
    background-color: ${colors.white};
    border-radius: ${scaleSpacing(15)};
  }
`;
