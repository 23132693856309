import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTheme } from "emotion-theming";

import { scaleSpacing, mediaquery } from "../../../../styles/theme";

const ContentWrapper = styled.div`
  grid-column: 1;
  grid-row: 2;
  position: relative;
  background: ${({ bgColor }) => bgColor};
  border-radius: ${scaleSpacing(10)} ${scaleSpacing(10)} 0 0;
  margin-top: calc(var(--initialvh, 1vh) * -10);
  margin-top: -58px;
  padding-top: ${scaleSpacing(8)};

  ${mediaquery.md(css`
    padding-top: ${scaleSpacing(14.5)};
  `)};
`;

const StickyWrapper = ({ children, component }) => {
  const theme = useTheme();
  return (
    <div>
      {component}
      <ContentWrapper bgColor={theme?.backgroundColorLight}>
        {children}
      </ContentWrapper>
    </div>
  );
};

StickyWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  isTeaser: PropTypes.bool,
  children: PropTypes.node.isRequired,
  withSearch: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  initialSearch: PropTypes.string,
  component: PropTypes.object,
};
StickyWrapper.defaultProps = {
  withSearch: false,
  isFullScreen: false,
};

export default React.memo(StickyWrapper);
