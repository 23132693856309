import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { UIContext } from "../../../contexts/ui";

import {
  colors,
  rem,
  zIndexes,
  hexRgba,
  mediaquery,
  getModalVideoTemplate,
} from "../../../../styles/theme";
import IconSvg from "../../Atoms/Svg";
import Image from "../../Atoms/Image";
import Button from "../../Atoms/Button";
import TextBox from "../../Atoms/TextBox";

const Container = styled.section`
  grid-column: 1;
  grid-row: 1;
  position: ${({ isSticky }) => (isSticky ? "sticky" : "relative")};
  top: 0;
`;

const TopContainer = styled.div`
  position: relative;
  height: ${({ height }) => `${height}vh`};

  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ isDarker }) =>
      isDarker ? hexRgba(colors.black, 0.4) : hexRgba(colors.black, 0.3)};
    z-index: ${zIndexes.middle};
  }
`;
const PlayButtonContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayButton = styled(Button)`
  margin: auto;
  z-index: ${zIndexes.top};
  padding: 1px;
  span {
    width: ${rem(8)};
  }

  ${mediaquery.md(css`
    span {
      width: ${rem(14)};
    }
  `)};
`;

const ImageContainer = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.middle};
`;

const HeroImage = ({
  backgroundImage,
  video,
  episodeNumber,
  description,
  title,
  ctaLabel,
  ctaLink,
  isSticky,
  heightVh,
}) => {
  const { openModal } = useContext(UIContext);
  const showPlayButton = !episodeNumber && video?.id;
  const showTextBox = description && title && ctaLabel;

  const showVideoModal = useCallback(() => {
    openModal({
      modalId: getModalVideoTemplate(video.player),
      modalParams: { video },
    });
  }, [openModal, video]);

  return (
    <Container isSticky={isSticky}>
      <TopContainer isDarker={showPlayButton} height={heightVh}>
        <ImageContainer>
          {backgroundImage && backgroundImage.url && (
            <Image
              source={backgroundImage}
              sizes="100vw"
              mobileSizes="100vw"
              objectFit="cover"
            />
          )}
        </ImageContainer>
        {showPlayButton && (
          <PlayButtonContainer onClick={showVideoModal}>
            <PlayButton buttonTheme="icon" ariaLabel="play" iconOnly>
              <IconSvg
                name="play"
                size={14}
                height={16}
                fill={colors.darkgrey2}
              />
            </PlayButton>
          </PlayButtonContainer>
        )}
        {showTextBox && (
          <TextBox
            description={description}
            title={title}
            ctaLabel={ctaLabel}
            ctaLink={ctaLink}
          />
        )}
      </TopContainer>
    </Container>
  );
};

HeroImage.propTypes = {
  backgroundImage: PropTypes.object.isRequired,
  video: PropTypes.object,
  onClickPlayButton: PropTypes.func,
  episodeNumber: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLink: PropTypes.object,
  ctaLabel: PropTypes.string,
  isSticky: PropTypes.bool,
  heightVh: PropTypes.number,
};

HeroImage.defaultProps = {
  onClickPlayButton: () => {},
  episodeNumber: null,
  isSticky: false,
  heightVh: 76,
};

export default React.memo(HeroImage);
