import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { colors, mediaquery, scaleSpacing } from "../../../../styles/theme";
import {
  OswaldOverline1,
  OswaldOverline4,
  OswaldH3,
  OswaldH6,
  NotoBody,
} from "../../Atoms/Typography";
import Image from "../../Atoms/Image";
import Button from "../../Atoms/Button";
import RichText from "../../Atoms/RichText";
import { decodeString } from "../../../utils";

function ContentBox({
  supertitle,
  image,
  title,
  subtitle,
  description,
  cta_label,
  cta_link,
}) {
  return (
    <Container>
      {image && <ImageContainer source={image} />}
      {supertitle && <Supertitle>{supertitle}</Supertitle>}
      {title && <Title>{title}</Title>}
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {description && (
        <StyledRichText
          data={description.raw.map(d => ({
            ...d,
            text: decodeString(d.text),
          }))}
        />
      )}
      {cta_link && cta_label && (
        <StyledButton buttonTheme="primaryRed" link={cta_link}>
          {cta_label}
        </StyledButton>
      )}
    </Container>
  );
}

ContentBox.propTypes = {
  supertitle: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.object,
  cta_label: PropTypes.string,
  cta_link: PropTypes.object,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  margin: var(--external-gutter);
  margin-top: 0;
  padding: ${scaleSpacing(5)} var(--external-gutter) ${scaleSpacing(10)};
  background-color: ${colors.lightgrey};
  min-height: 778px;

  ${mediaquery.md(css`
    margin: 0;
    border-radius: 0;
    padding: ${scaleSpacing(2)} ${scaleSpacing(6)} ${scaleSpacing(6)};
    align-items: center;
    min-height: 600px;
  `)};
`;

const Supertitle = styled.h2`
  ${OswaldOverline4}
  padding: ${scaleSpacing(1)} 0;

  ${mediaquery.md(css`
    ${OswaldOverline1}
    padding: ${scaleSpacing(2)} 0;
    order: -1;
  `)};
`;

const Title = styled.h3`
  ${OswaldH3}
  padding-top: ${scaleSpacing(1)};

  ${mediaquery.md(css`
    padding-top: ${scaleSpacing(2)};
  `)};
`;

const Subtitle = styled.h4`
  ${OswaldH6}
`;

const StyledRichText = styled(RichText)`
  ${NotoBody}
  margin: ${scaleSpacing(8)} ${scaleSpacing(10)} ${scaleSpacing(8)} 0;
  color: ${colors.mediumgrey2};

  ${mediaquery.md(css`
    margin: ${scaleSpacing(6)} 0;
    text-align: center;
  `)};

  strong {
    ${NotoBody}
    color: ${colors.darkgrey2};
    padding: 0;
  }

  p + p {
    margin-top: ${scaleSpacing(1)};
  }
`;

const StyledButton = styled(Button)`
  margin-top: ${scaleSpacing(2)};
`;

const ImageContainer = styled(Image)`
  width: 100%;
  height: auto;
  margin: ${scaleSpacing(1)} 0 ${scaleSpacing(5)};

  ${mediaquery.md(css`
    margin: ${scaleSpacing(2)} 0;
    width: 165px;
    height: auto;
  `)};

  img {
    display: block;
    width: 100%;
  }
`;

export default ContentBox;
