import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";

import { UIContext } from "../../../contexts/ui";
import {
  colors,
  mediaquery,
  rem,
  scaleSpacing,
  themes as colorThemes,
} from "../../../../styles/theme";
import {
  OswaldH1,
  OswaldH2,
  OswaldOverline4,
  NotoLarge,
  NotoLink,
} from "../../Atoms/Typography";
import { decodeString } from "../../../utils";

import Link from "../../Atoms/Link";
import RichText from "../../Atoms/RichText";
import AnchorLink from "./AnchorLink";
import IconSvg from "../../Atoms/Svg";
import Button from "../../Atoms/Button";
import SocialLinks from "./SocialLinks";

function Intro({
  article_author,
  author,
  description,
  facebookLink,
  format,
  is_not_legacy_article: showDescription,
  hasAnchorElement,
  publication_date,
  reading_time,
  sidebarCTALabel,
  sidebarCTALink,
  themes,
  title,
  twitterLink,
  website,
}) {
  const { t } = useTranslation();
  const { openModal } = useContext(UIContext);

  const { name: authorName, related_article: authorLink } = article_author
    .document?.data || { name: author };
  const authorHasArticle =
    authorLink && authorLink.url !== "/undefined/undefined";

  return (
    <section>
      <TagList>
        {[
          themes.map(({ theme }) => theme.document?.data.name),
          format,
          reading_time && `${reading_time} ${t("minutes_label")}`,
        ]
          .flat()
          .filter(Boolean)
          .map((s, i) => (
            <TagListItem key={i} color={colorThemes[website].primary}>
              <Tag>{s}</Tag>
            </TagListItem>
          ))}
      </TagList>
      <Title>{title}</Title>

      <TagList>
        {[
          authorName &&
            React.createElement(
              authorHasArticle ? AuthorLink : "span",
              {
                link: authorLink,
              },
              authorName
            ),
          publication_date && <span>{publication_date}</span>,
        ]
          .filter(Boolean)
          .map((s, i) => (
            <MetaTagListItem key={i} color={colors.mediumgrey2}>
              {s}
            </MetaTagListItem>
          ))}
      </TagList>
      <AnchorLink
        hasAnchorElement={hasAnchorElement}
        format={format}
        sidebarCTALabel={sidebarCTALabel}
        sidebarCTALink={sidebarCTALink}
        website={website}
      />
      {showDescription && description && (
        <>
          <Spacer color={colorThemes[website].primary} />
          <Description
            data={description.raw.map(d => ({
              ...d,
              text: decodeString(d.text),
            }))}
          />
        </>
      )}
      <SocialLinks facebookLink={facebookLink} twitterLink={twitterLink} />
      <ShareButton
        onClick={() => openModal({ modalId: "share" })}
        ariaLabel="share"
      >
        <IconSvg name="share" size={24} fill={colorThemes[website].color} />
      </ShareButton>
    </section>
  );
}

Intro.propTypes = {
  article_author: PropTypes.object,
  author: PropTypes.string,
  description: PropTypes.object,
  facebookLink: PropTypes.object,
  is_not_legacy_article: PropTypes.bool,
  format: PropTypes.string.isRequired,
  hasAnchorElement: PropTypes.bool.isRequired,
  publication_date: PropTypes.string.isRequired,
  reading_time: PropTypes.number,
  sidebarCTALabel: PropTypes.string,
  sidebarCTALink: PropTypes.object,
  themes: PropTypes.array,
  title: PropTypes.string.isRequired,
  twitterLink: PropTypes.object,
  website: PropTypes.string,
};

export default Intro;

const Title = styled.h1`
  ${OswaldH2}
  line-height: ${rem(48)};
  ${mediaquery.sm(css`
    ${OswaldH1}
    line-height: ${rem(56)};
  `)}
`;

const Description = styled(RichText)`
  ${NotoLarge}
  font-weight: 500;
  line-height: ${rem(36)};
  margin-top: 2rem;
`;

const Spacer = styled.hr`
  margin: 3rem 0 0;
  width: 2rem;
  height: 4px;
  background-color: ${({ color }) => color};
  border-style: none;
`;

const AuthorLink = styled(Link)`
  color: ${colors.mediumgrey2};
`;

const Tag = styled.span`
  ${OswaldOverline4}
  text-transform: uppercase;
`;

const TagList = styled.ul`
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  margin: ${scaleSpacing(8)} 0 ${scaleSpacing(6)};
  ${mediaquery.sm(css`
    margin: ${scaleSpacing(6)} 0;
  `)}
`;

const TagListItem = styled.li`
  display: flex;
  position: relative;
  color: ${({ color }) => color};
  margin: 0 ${scaleSpacing(4)} ${scaleSpacing(1)} 0;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: "";
    width: 1px;
    height: 9px;
    transform: translate(-8px, 5px);
    background-color: ${({ color }) => color};
    position: absolute;
    right: -${scaleSpacing(4)};
    top: 0;
  }
  &:last-of-type:after {
    display: none;
  }
`;

const MetaTagListItem = styled(TagListItem)`
  &,
  > * {
    ${NotoLink};
  }
`;

const ShareButton = styled(Button)`
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  ${mediaquery.sm(css`
    top: 3.5rem;
    right: 6.5rem;
  `)}
`;
