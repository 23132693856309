import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { mediaquery, rem, scaleSpacing } from "../../styles/theme";
import { css } from "@emotion/core";

import Layout from "../components/layout";
import StickyWrapper from "../components/Organisms/StickyWrapper";
import { adapter as seoAdapter, JsonLd } from "../components/seo";
import Article from "../components/Organisms/Article";
import Newsletter, {
  adapter as newsletterAdapter,
} from "../components/Molecules/Newsletter";
import HeroImage, {
  adapter as heroAdapter,
} from "../components/Molecules/HeroImage";
import { adConfigAdapter } from "../utils/configAdapter";
import AdBanner from "../components/Atoms/AdBanner";

import { withTrans } from "../i18n/withTrans";
import ContentBox from "../components/Organisms/Article/ContentBox";

const ArticlePage = ({ data, location, pageContext }) => {
  const { t } = useTranslation();
  const origin = typeof window !== "undefined" && window.location.origin;

  const {
    uid,
    type,
    data: {
      smartadserver_page_name,
      smartadserver_page_id,
      smartadserver_disable_masthead,
    },
  } = data.prismicArticle || {};

  const prismicPageContent = { uid, ...data.prismicArticle?.data };

  const prismicSiteConfiguration = data.allPrismicSiteConfiguration?.edges?.find(
    ({ node }) => node.data.site === pageContext.website
  ).node.data;

  const adsConfig = React.useMemo(
    () =>
      prismicSiteConfiguration &&
      adConfigAdapter(
        {
          ...prismicSiteConfiguration,
          smartadserver_page_name,
          smartadserver_page_id,
          smartadserver_disable_masthead,
        },
        type
      ),
    [
      prismicSiteConfiguration,
      smartadserver_disable_masthead,
      smartadserver_page_id,
      smartadserver_page_name,
      type,
    ]
  );

  if (!data.prismicArticle) return null;

  const articleSidebarContent =
    data.prismicArticle.data.sidebar_content?.document?.data;

  const hasOffset = data.prismicArticle.data?.body.some(
    ({ primary }) =>
      primary?.hasAnchorElement || data.prismicArticle.data?.sidebarCTALabel
  );
  return (
    <Layout
      seoData={seoAdapter(data.prismicArticle)}
      location={location}
      ads={adsConfig?.smartadserver_enable_masthead_article && adsConfig}
    >
      <JsonLd>
        {{
          "@context": "http://schema.org",
          "@type": "Article",
          mainEntityOfPage: {
            "@type": "WebPage",
            "@id": origin + data.prismicArticle.url,
          },
          headline: prismicPageContent.meta_title,
          description: prismicPageContent.meta_description,
          image: [prismicPageContent.meta_image?.url],
          datePublished: data.prismicArticle.first_publication_date,
          dateModified: data.prismicArticle.last_publication_date,
          author: {
            "@type": "Person",
            name: prismicPageContent.author,
          },
          publisher: {
            "@type": "Organization",
            "@name": "Trois couleurs",
            "@logo": {
              "@type": "ImageObject",
              url:
                "https://mk2-troiscouleurs.s3.amazonaws.com/wp-content/uploads/2019/04/TROISCOULEURS_noirsurfondblanc_RVB.jpg",
            },
          },
        }}
      </JsonLd>

      <StickyWrapper
        data={prismicPageContent}
        component={
          <HeroImage
            {...heroAdapter(prismicPageContent)}
            isSticky
            catId={uid}
          />
        }
      >
        <Container>
          <Article {...data.prismicArticle.data} ads={adsConfig} />
          <Sidebar>
            <SidebarTop hasOffset={hasOffset ? hasOffset : null}>
              <AdBanner
                config={{
                  ...adsConfig,
                  smartadserver_format_id: 96774,
                  isSticky: true,
                  scaleRatio: 0.8,
                }}
              />
            </SidebarTop>

            <SidebarBottom>
              {articleSidebarContent && (
                <ContentBox {...articleSidebarContent}></ContentBox>
              )}
              <AdBanner
                config={{
                  ...adsConfig,
                  smartadserver_format_id: 96776,
                  noFallback: true,
                }}
              />
            </SidebarBottom>
          </Sidebar>
        </Container>
        <Newsletter
          {...newsletterAdapter({ template: "troiscouleurs" }, { t })}
        />
      </StickyWrapper>
    </Layout>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaquery.md(css`
    flex-direction: row;
  `)}
`;

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${rem(300)};
  margin-top: 0;

  ${mediaquery.md(css`
    margin-left: var(--external-gutter);
    margin-right: calc(var(--external-gutter) * 2);
  `)}
`;

const SidebarTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: ${rem(300)};
  height: 100%;
  z-index: 0;
  margin-bottom: ${scaleSpacing(20)};

  ${({ hasOffset }) =>
    css`
      ${mediaquery.md(css`
        margin-top: ${hasOffset ? scaleSpacing(46) : scaleSpacing(25)};
        > div {
          top: ${scaleSpacing(25)};
        }
      `)}
    `}
`;

const SidebarBottom = styled.div`
  position: relative;
  margin-bottom: ${scaleSpacing(20)};
`;

export const query = graphql`
  query articleQuery($uid: String!) {
    ...i18n
    prismicArticle(uid: { eq: $uid }) {
      ...Article
      uid
      url
      type
      last_publication_date
      first_publication_date
      data {
        smartadserver_page_id
        smartadserver_page_name
        smartadserver_disable_masthead
        author
        themes {
          theme {
            document {
              ...Theme
            }
          }
        }
        sidebar_content {
          document {
            ... on PrismicArticleSidebar {
              id
              data {
                title
                supertitle
                subtitle
                cta_label
                cta_link {
                  ...Link
                }
                image {
                  ...Image
                }
                entry_title {
                  text
                }
                description {
                  raw
                }
              }
            }
          }
        }
        sidebarCTALabel: sidebar_cta_label
        sidebarCTALink: sidebar_cta_link {
          ...Link
        }
        facebookLink: facebook_profile {
          ...Link
        }
        twitterLink: twitter_profile {
          ...Link
        }
        relatedTags: related_tags {
          tag: related_tag {
            document {
              ... on PrismicTag {
                id
                data {
                  name
                }
              }
            }
          }
        }
        body {
          ... on PrismicArticleBodyMk2RichText {
            id
            primary {
              is_anchor_element
              rich_text_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicArticleBodyMk2Gallery {
            id
            primary {
              gallery_title
              is_anchor_element
            }
            items {
              image: gallery_image {
                ...Image
              }
            }
            slice_type
          }
          ... on PrismicArticleBodyMk2ArticleAd {
            id
            slice_type
          }
          ... on PrismicArticleBodyMk2Quote {
            id
            primary {
              quote_text
            }
            slice_type
          }
          ... on PrismicArticleBodyMk2ArticleLink {
            id
            primary {
              article_link_label
              article_link_title
              article_link_path {
                ...Link
              }
            }
            slice_type
          }
        }
      }
    }
    ...Configuration
  }
`;

ArticlePage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

ArticlePage.defaultProps = {
  data: {},
};

export default withPreview(withTrans(ArticlePage));
