import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { useTranslation } from "react-i18next";

import { mediaquery, scaleSpacing } from "../../../../styles/theme";
import { buttonStyle } from "../../../../styles/buttons";
import Button from "../../Atoms/Button";
import ArticleIcon from "./ArticleIcon";

function AnchorLink({
  format,
  hasAnchorElement,
  sidebarCTALabel,
  sidebarCTALink,
  website,
}) {
  const label = useLinkLabel(format);
  const btnStyle = buttonStyle(
    { trois_couleurs: "primaryDark", curiosity: "primary" }[website] ||
      "primary"
  );
  if (sidebarCTALink && sidebarCTALabel) {
    return (
      <StyledButton link={sidebarCTALink} btnStyle={btnStyle}>
        {sidebarCTALabel}
      </StyledButton>
    );
  }
  if (
    !hasAnchorElement ||
    [null, "article", "author"].includes(format?.toLowerCase())
  ) {
    return null;
  }
  return (
    <Anchor href="#article-anchor" btnStyle={btnStyle}>
      <ArticleIcon format={format} />
      <span>{label}</span>
    </Anchor>
  );
}

AnchorLink.propTypes = {
  format: PropTypes.string.isRequired,
  hasAnchorElement: PropTypes.bool.isRequired,
  sidebarCTALabel: PropTypes.string,
  sidebarCTALink: PropTypes.object,
  website: PropTypes.string,
};

export default AnchorLink;

function useLinkLabel(format) {
  const { t } = useTranslation();
  switch (format?.toLowerCase()) {
    case "podcast":
      return t("tc_articles_sidebar_cta_podcast");
    case "video":
      return t("tc_articles_sidebar_cta_video");
    case "gallery":
      return t("tc_articles_sidebar_cta_gallery");
    default:
      return t("tc_articlepush");
  }
}

const Anchor = styled.a`
  ${({ btnStyle }) => btnStyle}
  display: inline-flex;
  margin-top: 1rem;
  padding: 0 ${scaleSpacing(6)};

  ${mediaquery.sm(css`
    position: absolute;
    top: 6rem;
    right: 7rem;
  `)}

  & > * + * {
    margin-left: 1rem;
  }
`;

const StyledButton = styled(Button)`
  ${({ btnStyle }) => btnStyle}
  display: inline-flex;
  margin-top: 1rem;
  padding: 0 ${scaleSpacing(6)};

  ${mediaquery.sm(css`
    position: absolute;
    top: 6rem;
    right: 7rem;
  `)}
`;
