export default ({
  title,
  image,
  picture,
  hero_image,
  video_id,
  number,
  label,
  description,
  video_player,
  video_embed,
  video_price,
  smartadserver_disable_preroll,
  links,
  background_image,
}) => {
  return {
    title,
    label,
    description: description?.text,
    backgroundImage: hero_image || image || picture || background_image,
    video: {
      title: title,
      id: video_id,
      player: video_player,
      embed: video_embed,
      price: video_price,
      disablePreroll: smartadserver_disable_preroll,
    },
    episodeNumber: number,
    ctaLabel: links && links[0]?.link_label,
    ctaLink: links && links[0]?.link_path,
  };
};
