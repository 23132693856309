import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import RichText from "../../Atoms/RichText";
import Link from "../../Atoms/Link";
import { NotoBody, OswaldOverline4, SlaboQuote } from "../../Atoms/Typography";
import { colors, themes, mediaquery, rem } from "../../../../styles/theme";
import Gallery from "../../Molecules/Gallery";
import AdBanner from "../../Atoms/AdBanner";

function ArticleBody({ slice_type, primary, items, id, ads, website }) {
  const {
    gallery_title,
    article_link_path,
    article_link_label,
    article_link_title,
    quote_text,
    rich_text_content,
    is_anchor_element,
  } = primary || {};

  switch (slice_type) {
    case "mk2_gallery":
      return (
        <GallerySection id={is_anchor_element ? "article-anchor" : undefined}>
          <StyledGallery title={gallery_title || null} images={items} id={id} />
        </GallerySection>
      );
    case "mk2_rich_text":
      return (
        <section id={is_anchor_element ? "article-anchor" : undefined}>
          <StyledRichText data={rich_text_content?.raw} />
        </section>
      );
    case "mk2_quote":
      return (
        <QuoteSection website={website}>
          <Quote>{quote_text}</Quote>
        </QuoteSection>
      );
    case "mk2_article_link":
      return (
        <LinkSection>
          <LinkTitle>{article_link_title}</LinkTitle>
          <Link link={article_link_path}>{article_link_label}</Link>
        </LinkSection>
      );
    case "mk2_article_ad":
      return (
        <AdSection>
          <AdBanner config={{ ...ads, smartadserver_format_id: 96775 }} />
        </AdSection>
      );
    default:
      return null;
  }
}

ArticleBody.propTypes = {
  slice_type: PropTypes.string.isRequired,
  primary: PropTypes.object,
  ads: PropTypes.object,
  items: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  website: PropTypes.string,
};

export default ArticleBody;

const GallerySection = styled.section`
  section& {
    margin: 0;
    width: auto;
  }
`;

const StyledGallery = styled(Gallery)`
  padding: 0 calc(var(--external-gutter));
  ${mediaquery.sm(css`
    padding-left: calc(var(--external-gutter) * 2);
  `)}
`;

const StyledRichText = styled(RichText)`
  ${NotoBody}
  line-height: ${rem(32)};

  img {
    width: 100%;
    height: auto;
  }

  .embed.embed-youtube,
  .wp-block-embed-youtube.wp-block-embed {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  p + p,
  .preformatted > * + * {
    margin-top: 2rem;
  }
`;

const QuoteSection = styled.section`
  section& {
    margin: 0;
    width: auto;
    ${mediaquery.sm(css`
      margin: 0 calc(var(--external-gutter) * 2);
    `)}
  }
  display: flex;
  justify-content: center;
  background-color: ${({ website }) => themes[website].primary};
  color: ${colors.white};
  padding: 4rem 2rem;
`;

const AdSection = styled.section`
  section& {
    margin: 0;
    ${mediaquery.sm(css`
      margin: 0 calc(var(--external-gutter) * 2);
    `)}
  }
`;
const Quote = styled.p`
  ${SlaboQuote}
  text-align: center;

  ${mediaquery.sm(css`
    max-width: 66%;
  `)}
`;

const LinkSection = styled.section`
  padding: 2rem;
  background-color: ${colors.lightgrey};
  border-radius: 1rem;
`;

const LinkTitle = styled.h3`
  ${OswaldOverline4}
  color: ${themes.trois_couleurs.primary};
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;
