import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import { OswaldOverline3 } from "../../Atoms/Typography";
import Button from "../../Atoms/Button";

function RelatedTags({ relatedTags }) {
  const { t } = useTranslation();
  const tags = relatedTags
    ?.map(({ tag }) => tag.document?.data.name)
    .filter(Boolean);
  if (!tags?.length) return null;
  return (
    <section>
      <Title>{t("troiscouleurs_article_related_tags")}</Title>
      <List>
        {tags.map((tag, i) => (
          <li key={i}>
            <Button
              link={{ url: `/search?query=${encodeURIComponent(tag)}` }}
              buttonTheme="tagPrimary"
            >
              {tag}
            </Button>
          </li>
        ))}
      </List>
    </section>
  );
}

RelatedTags.propTypes = {
  relatedTags: PropTypes.array,
};

export default RelatedTags;

const Title = styled.h2`
  ${OswaldOverline3}
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;

  > li {
    margin-top: 1rem;
    margin-right: 1rem;
  }
`;
