import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import Intro from "./Intro";
import ArticleBody from "./ArticleBody";
import RelatedTags from "./RelatedTags";
import { mediaquery, themes } from "../../../../styles/theme";
import { css } from "@emotion/core";

function Article({ body, relatedTags, ads, ...rest }) {
  const hasAnchorElement = body.some(
    ({ primary }) => primary?.is_anchor_element
  );
  const website = process.env.GATSBY_WEBSITE;

  return (
    <Container website={website}>
      <Intro {...rest} hasAnchorElement={hasAnchorElement} website={website} />
      {body.map(b => {
        return (
          <ArticleBody
            {...b}
            website={website}
            key={b.id}
            ads={b.slice_type === "mk2_article_ad" ? ads : {}}
          />
        );
      })}
      <RelatedTags relatedTags={relatedTags} />
    </Container>
  );
}

Article.propTypes = {
  body: PropTypes.array,
  relatedTags: PropTypes.array,
  ads: PropTypes.object,
};

Article.defaultProps = {};

export default Article;

const Container = styled.article`
  margin-bottom: 1rem;
  overflow: hidden;
  color: ${({ website }) => themes[website].color};

  ${mediaquery.sm(css`
    margin-bottom: 4rem;
  `)}

  section {
    margin: 2rem var(--external-gutter);

    ${mediaquery.sm(css`
      margin-left: calc(var(--external-gutter) * 2);
    `)}

    &:target {
      transition: all 1s linear;
      a {
        font-weight: 500;
      }
    }
  }
`;
