import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import Layout from "../components/layout";
import Newsletter, {
  adapter as newsletterAdapter,
} from "../components/Molecules/Newsletter";
import Distribution, {
  adapter as distributionAdapter,
} from "../components/Organisms/Distribution";
import Edito, { adapter as editoAdapter } from "../components/Organisms/Edito";
import StickyWrapper from "../components/Organisms/StickyWrapper";
import Informations, {
  adapter as informationsAdapter,
} from "../components/Organisms/Informations";
import { withTrans } from "../i18n/withTrans";
import FigureList, {
  articlesFigureListAdapter,
} from "../components/Molecules/FigureList";
import Gallery from "../components/Molecules/Gallery";
import HeroImage, {
  adapter as heroAdapter,
} from "../components/Molecules/HeroImage";
import AdBanner from "../components/Atoms/AdBanner";
import { adConfigAdapter } from "../utils/configAdapter";

import { adapter as seoAdapter } from "../components/seo";
import { mediaquery, breakpoints, colors } from "../../styles/theme";

const Filmpage = ({ data, location, pageContext }) => {
  const { t } = useTranslation();

  const {
    uid,
    type,
    data: {
      smartadserver_page_name,
      smartadserver_page_id,
      smartadserver_disable_masthead,
    },
  } = data.prismicFilm || {};

  const prismicPageContent = { uid, ...data.prismicFilm.data };

  const prismicLastArticlesContent = data.allPrismicArticle?.edges;

  const prismicSiteConfiguration = data.allPrismicSiteConfiguration?.edges?.find(
    ({ node }) => node.data.site === pageContext.website
  ).node.data;

  const adsConfig = React.useMemo(
    () =>
      prismicSiteConfiguration &&
      adConfigAdapter(
        {
          ...prismicSiteConfiguration,
          smartadserver_page_name,
          smartadserver_page_id,
          smartadserver_disable_masthead,
        },
        type
      ),
    [
      prismicSiteConfiguration,
      smartadserver_disable_masthead,
      smartadserver_page_id,
      smartadserver_page_name,
      type,
    ]
  );

  return (
    <Layout
      seoData={seoAdapter(data.prismicFilm)}
      location={location}
      ads={adsConfig}
    >
      <StickyWrapper
        data={prismicPageContent}
        component={<HeroImage {...heroAdapter(prismicPageContent)} isSticky />}
      >
        <Informations
          {...informationsAdapter(prismicPageContent, { t })}
          showDescription={false}
        />
        <Distribution {...distributionAdapter(prismicPageContent, { t })} />
        <AdBanner
          config={{
            ...adsConfig,
            smartadserver_format_id: 94952,
            bgColor: "black",
            hideOnMobile: true,
          }}
        />
        <Edito {...editoAdapter(prismicPageContent)} />
        <StyledGallery
          {...prismicPageContent}
          title={t("curiosity_imagesCarousel_title")}
          withScrollbar
          breakpoints={{
            [breakpoints.sm]: {
              spaceBetween: 24,
              freeMode: true,
            },
          }}
        />
        {prismicLastArticlesContent && (
          <FigureList
            {...articlesFigureListAdapter({
              lastArticles: prismicLastArticlesContent,
              articlesPush: prismicPageContent.articles_push,
              maxitems: 2,
            })}
            title={t("curiosity_articlepush")}
            columns={2}
          />
        )}
        <Newsletter {...newsletterAdapter({ template: "curiosity" }, { t })} />
      </StickyWrapper>
    </Layout>
  );
};

export const query = graphql`
  query filmQuery($uid: String!) {
    ...i18n
    prismicFilm(uid: { eq: $uid }) {
      uid
      url
      type
      data {
        smartadserver_page_id
        smartadserver_page_name
        smartadserver_disable_masthead
        meta_title
        meta_description
        meta_image {
          ...Image
        }
        themes {
          theme {
            document {
              ...Theme
            }
          }
        }
        universes {
          universe {
            document {
              ...Universe
            }
          }
        }
        category
        country
        duration
        genre
        hd
        free
        excerpt
        entry_title
        language
        title
        trailer_id
        trailer_player
        trailer_embed
        video_id
        video_player
        video_embed
        video_price
        smartadserver_disable_preroll
        year
        image {
          ...Image
        }
        images: gallery {
          image {
            ...Image
          }
        }
        edito_image {
          ...Image
        }
        director
        directors {
          film_director {
            document {
              ...Director
            }
          }
        }
        actors {
          actor_name
          actor_role
          actor {
            document {
              ...Actor
            }
          }
        }
        edito_description {
          ...StructuredText
        }
        description {
          ...StructuredText
        }
        edito_link {
          ...Link
        }
        edito_link_label
        edito_author
        edito_formatted_publication_date: edito_publication_date(
          formatString: "LL"
          locale: "fr-fr"
        )
        edito_publication_date
        sponsors_title
        sponsors_description {
          ...StructuredText
        }
        sponsors {
          sponsor {
            document {
              ...Sponsor
            }
          }
        }
        articles_push {
          article {
            document {
              ...Article
            }
          }
        }
      }
    }
    ...ExternalArticles
    ...Configuration
  }
`;

Filmpage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  pageContext: PropTypes.object,
};

Filmpage.defaultProps = {
  data: {},
};

export default withPreview(withTrans(Filmpage));

const StyledGallery = styled(Gallery)`
  color: white;
  background-color: ${colors.black};
  padding: var(--external-gutter);
  ${mediaquery.sm(css`
    padding: calc(var(--external-gutter) + var(--col));
  `)}
`;
