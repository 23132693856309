import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import {
  OswaldH1,
  OswaldH2,
  NotoBody,
  NotoBodySmall,
} from "../../Atoms/Typography";
import EyeIcon from "../../Atoms/EyeIcon";
import Button from "../../Atoms/Button";
import RichText from "../../Atoms/RichText";
import {
  scaleSpacing,
  colors,
  mediaquery,
  zIndexes,
} from "../../../../styles/theme";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${scaleSpacing(8)} ${scaleSpacing(6)};
  max-width: ${scaleSpacing(125)};
  max-height: calc(var(--initialvh, 1vh) * 85);
  background-color: ${colors.white};
  border-radius: ${scaleSpacing(2)};
  position: absolute;
  left: calc(var(--external-gutter) * 1.2);
  right: calc(var(--external-gutter) * 1.2);
  bottom: calc(var(--external-gutter) * 1.2);
  z-index: ${zIndexes.top};
  overflow-y: auto;

  ${mediaquery.xs(css`
    padding: ${scaleSpacing(10)} ${scaleSpacing(8)};
    ${OswaldH1};
  `)};

  ${mediaquery.sm(css`
    width: calc(var(--col) * 16 - var(--gutter-size));
  `)}

  ${mediaquery.md(css`
    right: initial;
    left: calc(var(--external-gutter) * 2);
    bottom: calc(var(--external-gutter) * 2);
    width: calc(var(--col) * 24 - var(--gutter-size));
  `)};
`;

const Title = styled.h1`
  ${OswaldH2};
  color: ${colors.black};
  margin-bottom: ${scaleSpacing(4)};
  ${mediaquery.xs(css`
    margin-bottom: ${scaleSpacing(6)};
    ${OswaldH1};
  `)};
`;

const Description = styled(RichText)`
  ${NotoBodySmall};
  margin-bottom: ${scaleSpacing(6)};
  ${mediaquery.xs(css`
    ${NotoBody};
  `)};
`;

const StyledButton = styled(Button)`
  padding: 0 ${scaleSpacing(6)};

  span {
    margin-left: ${scaleSpacing(0.5)};

    span {
      margin-left: ${scaleSpacing(3)};
    }
  }
`;

const TextBox = ({ description, title, ctaLabel, ctaLink }) => {
  return (
    <FlexContainer>
      {!!title && (
        <Title>
          <RichText data={title} clamp={3} />
        </Title>
      )}
      {!!description && <Description data={description} clamp={8} />}
      <StyledButton
        buttonTheme="primaryDark"
        ariaLabel={ctaLabel}
        link={ctaLink}
      >
        <EyeIcon active color="white" />
        <span>{ctaLabel}</span>
      </StyledButton>
    </FlexContainer>
  );
};

TextBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  ctaLink: PropTypes.object,
  ctaLabel: PropTypes.string,
};

export default React.memo(TextBox);
